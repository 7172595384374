import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "grandTotal", "marker", "unitPriceField", "quantityField", "discountRateField", "subtotalField", "itemLine", "paymentTerm", "personInChargeName", "personInChargeContact", "supplierInput", "supplierUrl" ]

  connect() {
    this.refreshMarker()
  }

  markerTargetConnected() {
    this.refreshMarker()
  }

  markerTargetDisconnected() {
    this.refreshMarker()
  }

  refreshMarker() {
    this.markerTargets.forEach((marker, index) => {
      marker.value = index + 1
    })
  }

  coerceNumber(maybe_number) {
    const maybe_num = parseFloat(maybe_number)

    return isNaN(maybe_num) ? 0.00 : maybe_num
  }
}
import { TimeScale } from 'chart.js';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "marker", "unitPriceField", "quantityField", "grandTotal", "itemLine", "subtotalField", "itemLineCounter", "addItemsLink" ]

  connect() {
    this.refreshMarker()
  }

  markerTargetConnected() {
    this.refreshMarker()
  }

  markerTargetDisconnected() {
    this.refreshMarker()
  }

  refreshMarker() {
    this.markerTargets.forEach((marker, index) => {
      marker.value = index + 1
    })
  }

  coerceNumber(maybe_number) {
    const maybe_num = parseFloat(maybe_number)

    return isNaN(maybe_num) ? 0.00 : maybe_num
  }

  calculateLines(){
    this.itemLineTargets.forEach(line => {
      this.calculateLine(line)
    })
  }

  calculate() {

    this.calculateLines()

    this.calculateGrandTotalAmount()
    // this.calculateItemLines()
    // this.calculateGrandTotal()
    // const overtimeHours = this.coerceNumber(line.querySelector('[data-payday-target="overtimeHours"]').value)
  }

  updateItemFields() {
    const currentLineCounters = this.itemLineTargets.length
    if(currentLineCounters != this.itemLineCounterTarget.value) {
      console.log(currentLineCounters)

      if(currentLineCounters > this.itemLineCounterTarget.value) {
        const toRemove = currentLineCounters - this.itemLineCounterTarget.value
        // console.log('remove')
        for(let i = 0; i < toRemove ; i++) {
          // debugger
          this.itemLineTargets[this.itemLineTargets.length - 1].remove()
          // this.itemLineTargets.pop()
        }
      } else if (currentLineCounters < this.itemLineCounterTarget.value) {
        const toAdd = this.itemLineCounterTarget.value - currentLineCounters

        for(let i = 0; i < toAdd ; i++) {
          this.addItemsLinkTarget.click();
        }
      }
    }
  }

  incrementItemLineCounter() {
    this.itemLineCounterTarget.value = this.itemLineTargets.length + 1
  }

  decrementItemLineCounter() {
    this.itemLineCounterTarget.value = this.itemLineTargets.length - 1
  }

  calculateLine(line){
    const unitPrice = this.coerceNumber(line.querySelector('[data-purchase-invoice-target="unitPriceField"]').value)
    const quantity = this.coerceNumber(line.querySelector('[data-purchase-invoice-target="quantityField"]').value)
    // const discountRate = this.coerceNumber(line.querySelector('[data-purchase-invoice-target="discountRateField"]').value)

    line.querySelector('[data-purchase-invoice-target="subtotalField"]').value = (unitPrice * quantity).toFixed(2)
  }

  calculateGrandTotalAmount() {
    const grandTotal = this.subtotalFieldTargets.map(field => field.value).reduce((sum, x) =>
      this.coerceNumber(sum) + this.coerceNumber(x)
    )

    this.grandTotalTarget.value = this.coerceNumber(grandTotal).toFixed(2)
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "materialSearchField", "materialRow", "materialDescription" ]

  executeSearch() {
    const input = this.materialSearchFieldTarget.value.toLowerCase()
    if(input.length < 1) {
      this.showAllRows()
    } else {
      debugger
      this.materialDescriptionTargets.forEach(target => {
        if(target.innerHTML.toLowerCase().includes(input)) {
          target.closest('[data-materials-table-target="materialRow"]').style.display = '';
        } else {
          target.closest('[data-materials-table-target="materialRow"]').style.display = 'none';
        }
      })
    }
  }

  showAllRows() {
    this.materialDescriptionTargets.forEach(target => {
      target.closest('[data-materials-table-target="materialRow"]').style.display = '';
    })
  }
}